import type { Environment } from './types';
import { sharedProdEnv, sharedJuniperEnv, sharedProdAuEnv } from './shared';

export const env: Environment = {
  ...sharedProdEnv,
  ...sharedJuniperEnv,
  ...sharedProdAuEnv,
  adminAppUrl: 'https://admin.myjuniper.com',
  logoUrl: 'https://assets.myjuniper.com/juniper-logo.svg',
};
